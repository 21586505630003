// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nondiscrimination-js": () => import("./../../../src/pages/nondiscrimination.js" /* webpackChunkName: "component---src-pages-nondiscrimination-js" */),
  "component---src-pages-payment-and-insurance-js": () => import("./../../../src/pages/payment-and-insurance.js" /* webpackChunkName: "component---src-pages-payment-and-insurance-js" */),
  "component---src-pages-philosophy-of-care-js": () => import("./../../../src/pages/philosophy-of-care.js" /* webpackChunkName: "component---src-pages-philosophy-of-care-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-procedure-and-recovery-js": () => import("./../../../src/pages/procedure-and-recovery.js" /* webpackChunkName: "component---src-pages-procedure-and-recovery-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-request-an-appointment-js": () => import("./../../../src/pages/request-an-appointment.js" /* webpackChunkName: "component---src-pages-request-an-appointment-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-templates-team-member-dup-js": () => import("./../../../src/templates/teamMemberDup.js" /* webpackChunkName: "component---src-templates-team-member-dup-js" */)
}

