import 'whatwg-fetch';
// import * as Sentry from '@sentry/browser';

export const onInitialClientRender = () => {
  // remove the JSS style tag generated on the server to avoid conflicts with the one added on the client
  // eslint-disable-next-line no-undef
  const ssStyles = window.document.getElementById(`server-side-jss`);
  ssStyles && ssStyles.parentNode.removeChild(ssStyles);

  // add intercom to the head.

  if (typeof Intercom === `function` && window.IntercomAppId) {
    window.Intercom('boot', {
      app_id: window.IntercomAppId,
    });
  }
};

export const onRouteUpdate = function({ location }) {
  if (typeof Intercom === `function` && window.IntercomAppId) {
    window.Intercom('update');
    window.Intercom('trackEvent', 'Viewed Page', {
      page: location.pathname,
    });
  }
};

// export const onClientEntry = function(_, pluginParams) {
//   if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//       environment: process.env.NODE_ENV,
//       dsn: 'https://55713b5594374affbdc8a5cef2057891@sentry.io/1047080',
//       // release: rev.long(),
//     });
//   }
// };
